import { FormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import {
  inject,
  OnInit,
  Output,
  OnDestroy,
  ViewChild,
  Component,
  DestroyRef,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core'

import {
  EntityInterfaces,
  BackendService,
  ErrorService
} from '@goplanner/api-client'
import { IAction, IColumn, SatinelGridModule } from '@satinel-system/grid'

import { InputTextModule } from 'primeng/inputtext'
import { ButtonModule } from 'primeng/button'
import { DialogModule } from 'primeng/dialog'
import { SplitterModule } from 'primeng/splitter'
import { DropdownModule } from 'primeng/dropdown'
import { SelectButtonModule } from 'primeng/selectbutton'
import { ProgressSpinnerModule } from 'primeng/progressspinner'

import { AxisVisualizerComponent } from 'src/app/shared/components/wheels/axis-visualizer/axis-visualizer.component'
import { of, take, zip } from 'rxjs'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { ConfirmationService, MessageService } from 'primeng/api'

import { TranslocoService } from '@jsverse/transloco'

export interface Eje {
  id: number
  tipo: 'd' | 'm' | 'r'
  n_ruedas: 2 | 4
}

@Component({
  selector: 'app-axis',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    DialogModule,
    RouterModule,
    DropdownModule,
    SplitterModule,
    InputTextModule,
    SatinelGridModule,
    SelectButtonModule,
    ProgressSpinnerModule,
    AxisVisualizerComponent
  ],
  templateUrl: './axis.component.html',
  styleUrl: './axis.component.scss'
})
export class AxisComponent implements OnInit, OnDestroy {
  readonly destroyRef = inject(DestroyRef)

  visible = true

  mode: 'route' | 'dialog' = 'route'

  // importo componente hijo
  @ViewChild('axisVisualizer') axisVisualizer!: AxisVisualizerComponent

  @Output()
  destroy = new EventEmitter<void>()

  @Output()
  selecter = new EventEmitter<number>()

  //variables
  opcionesClaseVehiculo = [
    { label: 'Tipo Tractora', icon: 'veh veh-tractora', value: 'isTractora' },
    { label: 'Tipo Remolque', icon: 'veh veh-remolque', value: 'isRemolque' }
  ]
  opcionClaseVehiculo: 'isTractora' | 'isRemolque' = 'isTractora'

  filteredOption: 'isTractora' | 'isRemolque' | undefined

  creatingConfiguracion = false

  ejes: Eje[] = []

  configName: string = ''

  ejesOptions: {
    label: string
    value: string
  }[] = []

  ruedasOptions = [
    {
      label: '2 ruedas',
      value: 2
    },
    {
      label: '4 ruedas',
      value: 4
    }
  ]

  actions: IAction<
    EntityInterfaces['sys_ent_veh_configuracion_ejes']['get']
  >[] = [
    {
      tooltip: 'Editar',
      icon: 'pi pi-pencil',
      color: 'p-button-success',
      handler: (item) => {
        this.selectConfigToEdit(item)
      }
    },
    {
      tooltip: 'Borrar',
      icon: 'pi pi-trash',
      color: 'p-button-danger',
      handler: (item) => {
        this.deleteConfig(item)
      }
    }
  ]

  columns: IColumn<
    EntityInterfaces['sys_ent_veh_configuracion_ejes']['get']
  >[] = [
    {
      field: 'nombre',
      header: 'Nombre'
    },
    {
      field: 'configuracion_remolque',
      header: 'Tipo de Vehículo',
      renderer(data) {
        if (data.configuracion_remolque)
          return '<i class="veh veh-remolque mr-2"></i> Remolque'
        else return '<i class="veh veh-tractora mr-2"></i> Tractora'
      }
    }
  ]

  loading = false
  saving = false

  configuraciones: EntityInterfaces['sys_ent_veh_configuracion_ejes']['get'][] =
    []

  selectedId!: number

  selectedConfiguracion:
    | EntityInterfaces['sys_ent_veh_configuracion_ejes']['get']
    | EntityInterfaces['sys_ent_veh_configuracion_ejes']['get'][] = []
  editedConfiguracion:
    | EntityInterfaces['sys_ent_veh_configuracion_ejes']['get']
    | undefined

  constructor(
    private errorService: ErrorService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private backendService: BackendService,
    private translate: TranslocoService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.filteredOption) this.opcionClaseVehiculo = this.filteredOption
    this.loading = true
    // carga las configuraciones y guarda la seleccionada
    this.backendService
      .getAll('sys_ent_veh_configuracion_ejes')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((configuraciones) => {
        this.configuraciones = configuraciones.filter(
          (c) =>
            !this.filteredOption ||
            c.configuracion_remolque ===
              (this.filteredOption === 'isRemolque' ? true : false)
        )
        if (
          this.selectedId &&
          (!this.selectedConfiguracion ||
            Array.isArray(this.selectedConfiguracion))
        )
          this.selectedConfiguracion =
            this.configuraciones.find((conf) => conf.id === this.selectedId) ??
            []
        if (this.selectedConfiguracion)
          this.calculateEjes(this.selectedConfiguracion)
        this.loading = false
      })

    if (this.editedConfiguracion) this.loadConfig(this.editedConfiguracion)
  }

  // al cerrar modal vuelve al path anterior
  onDestroy() {
    this.visible = false
    this.destroy.emit()
  }

  ngOnDestroy() {
    this.onDestroy()
  }

  selectConfiguracion() {
    if (
      !this.selectedConfiguracion ||
      Array.isArray(this.selectedConfiguracion)
    )
      return
    this.selecter.emit(this.selectedConfiguracion.id)
    this.onDestroy()
  }

  configurarEjes() {
    if (this.opcionClaseVehiculo === 'isTractora') {
      this.ejes = [
        {
          id: -1,
          tipo: 'd',
          n_ruedas: 2
        },
        {
          id: -1,
          tipo: 'm',
          n_ruedas: 2
        }
      ]
    } else if (this.opcionClaseVehiculo === 'isRemolque') {
      this.ejes = [
        {
          id: -1,
          tipo: 'r',
          n_ruedas: 4
        }
      ]
    }
    this.configurarOpciones()
  }

  configurarOpciones() {
    if (this.opcionClaseVehiculo === 'isTractora') {
      this.ejesOptions = [
        {
          label: 'Directriz',
          value: 'd'
        },
        {
          label: 'Motriz',
          value: 'm'
        }
      ]
    } else if (this.opcionClaseVehiculo === 'isRemolque') {
      this.ejesOptions = [
        {
          label: 'Remolque',
          value: 'r'
        },
        {
          label: 'Motriz',
          value: 'm'
        }
      ]
    }
  }

  addAxis() {
    if (this.ejes.length === 6) return
    if (this.opcionClaseVehiculo === 'isTractora' && this.ejes.length === 0) {
      this.ejes.push({
        id: -1,
        tipo: 'd',
        n_ruedas: 2
      })
    } else if (this.opcionClaseVehiculo === 'isTractora') {
      this.ejes.push({
        id: -1,
        tipo: 'm',
        n_ruedas: 2
      })
    } else if (this.opcionClaseVehiculo === 'isRemolque') {
      this.ejes.push({
        id: -1,
        tipo: 'r',
        n_ruedas: 4
      })
    }
  }

  removeAxis(index: number) {
    this.ejes.splice(index, 1)
  }

  ejeChange(index: number) {
    if (this.ejes[index].tipo === 'd') this.ejes[index].n_ruedas = 2
    if (this.ejes[index].tipo === 'r') this.ejes[index].n_ruedas = 4
  }

  calculateEjes(
    configuracion?:
      | EntityInterfaces['sys_ent_veh_configuracion_ejes']['get']
      | EntityInterfaces['sys_ent_veh_configuracion_ejes']['get'][]
  ) {
    if (!configuracion || Array.isArray(configuracion)) {
      this.ejes = []
      return
    }
    this.opcionClaseVehiculo = configuracion.configuracion_remolque
      ? 'isRemolque'
      : 'isTractora'
    this.ejes =
      configuracion.sys_ent_veh_configuracion_ejes_eje
        ?.sort((a, b) => a.posicion - b.posicion)
        .map(({ id, n_ruedas, id_tipo_eje }) => ({
          id,
          n_ruedas: n_ruedas as 2 | 4,
          tipo: id_tipo_eje === 1 ? 'd' : id_tipo_eje === 2 ? 'm' : 'r'
        })) ?? []
    this.configurarOpciones()
  }

  createNewConfig() {
    this.creatingConfiguracion = true
    this.configName = ''
    this.configurarEjes()
  }

  loadConfig(
    configuracion: EntityInterfaces['sys_ent_veh_configuracion_ejes']['get']
  ) {
    this.configName = configuracion.nombre as string
    this.opcionClaseVehiculo = configuracion.configuracion_remolque
      ? 'isRemolque'
      : 'isTractora'
    this.ejes =
      configuracion.sys_ent_veh_configuracion_ejes_eje
        ?.sort((a, b) => a.posicion - b.posicion)
        .map(({ id, n_ruedas, id_tipo_eje }) => ({
          id,
          n_ruedas: n_ruedas as 2 | 4,
          tipo: id_tipo_eje === 1 ? 'd' : id_tipo_eje === 2 ? 'm' : 'r'
        })) ?? []
  }

  createConfig() {
    const data: EntityInterfaces['sys_ent_veh_configuracion_ejes']['post'] = {
      nombre: this.configName,
      configuracion_remolque: this.opcionClaseVehiculo === 'isRemolque'
      // TODO: problema campo revisar Debora
      /*sys_ent_veh_configuracion_ejes_eje: this.ejes.map(
        ({ tipo, n_ruedas }, posicion) => ({
          n_ruedas,
          posicion,
          id_tipo_eje: this.mapTipo[tipo]
        })
      )*/
    }
    this.saving = true
    this.backendService
      .post('sys_ent_veh_configuracion_ejes', data)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.messageService.add({
            severity: 'success',
            summary: 'Hecho',
            detail: 'Evento creado correctamente',
            life: 3000
          })
          this.saving = false
          this.creatingConfiguracion = false
          this.selectedId = res.id
          this.selectedConfiguracion = res
          this.cdr.detectChanges()
        },
        error: (error) => {
          this.errorService.showErrorDialog(
            'sys_ent_veh_configuracion_ejes',
            [],
            error,
            'create'
          )
          this.saving = false
          this.cdr.detectChanges()
        }
      })
  }

  editConfig(
    configuracion: EntityInterfaces['sys_ent_veh_configuracion_ejes']['get']
  ) {
    const dataHeader: EntityInterfaces['sys_ent_veh_configuracion_ejes']['put'] =
      {
        id: configuracion.id,
        nombre: this.configName,
        configuracion_remolque: this.opcionClaseVehiculo === 'isRemolque'
      }
    let mustUpdateHeader = false
    if (
      dataHeader.nombre !== (configuracion.nombre as string) ||
      dataHeader.configuracion_remolque !== configuracion.configuracion_remolque
    )
      mustUpdateHeader = true

    const dataEjesCreate: EntityInterfaces['sys_ent_veh_configuracion_ejes_eje']['post'][] =
      []
    const dataEjesUpdate: EntityInterfaces['sys_ent_veh_configuracion_ejes_eje']['put'][] =
      []
    const dataEjesDelete: EntityInterfaces['sys_ent_veh_configuracion_ejes_eje']['delete'][] =
      []
    this.ejes.forEach((eje, index) => {
      // Si existe una configuración en ese índice
      if (configuracion.sys_ent_veh_configuracion_ejes_eje?.[index]) {
        const config = configuracion.sys_ent_veh_configuracion_ejes_eje[index]
        if (config.id === eje.id) {
          if (
            config.id_tipo_eje !== this.mapTipo[eje.tipo] ||
            config.n_ruedas !== eje.n_ruedas
          ) {
            dataEjesUpdate.push({
              id: eje.id,
              id_tipo_eje: this.mapTipo[eje.tipo],
              n_ruedas: eje.n_ruedas as 2 | 4
            })
          }
        } else {
          dataEjesCreate.push({
            id_configuracion_eje: configuracion.id,
            posicion: index,
            id_tipo_eje: this.mapTipo[eje.tipo],
            n_ruedas: eje.n_ruedas as 2 | 4
          })
        }
      } else {
        dataEjesCreate.push({
          id_configuracion_eje: configuracion.id,
          posicion: index,
          id_tipo_eje: this.mapTipo[eje.tipo],
          n_ruedas: eje.n_ruedas as 2 | 4
        })
      }
    })

    configuracion.sys_ent_veh_configuracion_ejes_eje?.forEach((eje, index) => {
      if (!this.ejes[index] || this.ejes[index].id !== eje.id) {
        dataEjesDelete.push(eje.id)
      }
    })

    if (
      !mustUpdateHeader &&
      !dataEjesCreate.length &&
      !dataEjesUpdate.length &&
      !dataEjesDelete.length
    ) {
      this.messageService.add({
        severity: 'info',
        summary: 'No hay cambios',
        detail: 'No se ha realizado ningún cambio en la configuración',
        life: 3000
      })
    }

    this.saving = true
    zip(
      mustUpdateHeader
        ? this.backendService
            .put('sys_ent_veh_configuracion_ejes', dataHeader)
            .pipe(take(1))
        : of(null),
      dataEjesDelete.length
        ? this.backendService
            .delete('sys_ent_veh_configuracion_ejes_eje', dataEjesDelete)
            .pipe(take(1))
        : of(null),
      dataEjesUpdate.length
        ? this.backendService
            .put('sys_ent_veh_configuracion_ejes_eje', dataEjesUpdate)
            .pipe(take(1))
        : of(null),
      dataEjesCreate.length
        ? this.backendService
            .post('sys_ent_veh_configuracion_ejes_eje', dataEjesCreate)
            .pipe(take(1))
        : of(null)
    ).subscribe({
      next: () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Hecho',
          detail: 'Configuración actualizada correctamente',
          life: 3000
        })
        this.saving = false
        this.editedConfiguracion = undefined
        this.selectedId = configuracion.id
        this.backendService
          .getById('sys_ent_veh_configuracion_ejes', configuracion.id)
          .pipe(take(1))
          .subscribe((c) => (this.selectedConfiguracion = c))
        this.cdr.detectChanges()
      },
      error: (error) => {
        this.errorService.showErrorDialog(
          'sys_ent_veh_configuracion_ejes',
          [],
          error,
          'update'
        )
        this.saving = false
        this.cdr.detectChanges()
      }
    })
  }

  selectConfigToEdit(
    configuracion: EntityInterfaces['sys_ent_veh_configuracion_ejes']['get']
  ) {
    this.configName = configuracion.nombre as string
    this.editedConfiguracion = configuracion
    this.calculateEjes(configuracion)
  }

  saveConfig() {
    if (!this.configName) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Falta el nombre de la configuración',
        life: 3000
      })
      return
    }

    if (this.editedConfiguracion) {
      this.editConfig(this.editedConfiguracion)
    } else if (this.creatingConfiguracion) {
      this.createConfig()
    }
  }

  mapTipo = {
    d: 1,
    m: 2,
    r: 3
  }

  cancelConfig() {
    this.creatingConfiguracion = false
    this.editedConfiguracion = undefined
    if (this.selectedConfiguracion)
      this.calculateEjes(this.selectedConfiguracion)
  }

  deleteConfig(
    item: EntityInterfaces['sys_ent_veh_configuracion_ejes']['get']
  ) {
    this.confirmationService.confirm({
      header: this.translate.translate(
        'goplanner.COMMON.DELETE_REGISTRY_TITLE'
      ),
      message: this.translate.translate(
        'goplanner.COMMON.DELETE_REGISTRY_MESSAGE'
      ),
      acceptLabel: this.translate.translate(
        'goplanner.COMMON.DELETE_REGISTRY_ACCEPT'
      ),
      rejectLabel: this.translate.translate(
        'goplanner.COMMON.DELETE_REGISTRY_REJECT'
      ),
      acceptButtonStyleClass: 'p-button-danger',
      rejectButtonStyleClass: 'p-button-secondary p-button-text',
      acceptIcon: 'pi pi-trash mr-2',
      rejectIcon: ' ',
      accept: () => {
        this.backendService
          .delete('sys_ent_veh_configuracion_ejes', item.id)
          .pipe(take(1))
          .subscribe({
            next: () => {
              this.messageService.add({
                severity: 'success',
                summary: 'Hecho',
                detail: 'Configuración eliminada correctamente',
                life: 3000
              })
              this.cdr.detectChanges()
            },
            error: (error) => {
              this.errorService.showErrorDialog(
                'sys_ent_veh_configuracion_ejes',
                [],
                error,
                'delete'
              )
              this.cdr.detectChanges()
            }
          })
      }
    })
  }
}
